import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user = localStorage.getItem('user')
    let isAuthenticated = false;

    if (user) {
      isAuthenticated = true
    }

    if (isAuthenticated) {
      return true;
    } else {
      var regex = /\/plan-document-review/;
      if (state.url && regex.test(state.url)) {
        localStorage.setItem('redirectUrl', state.url)
      }else{
        localStorage.clear();
      }
      return this.router.navigate(['/soPhia/login']);
    }
  }
}
