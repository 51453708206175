import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ApiInterceptor } from '../shared/interceptors/api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppParams } from './consts/app.config';
import { DatePipe } from '@angular/common';
import { BASE_PATH } from 'src/app/generated';
import { environment } from 'src/environments/environment';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CKEditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NoopAnimationsModule,
    NgbModule,
    NgxFileDropModule,
  ],

  exports: [NgxFileDropModule],
  providers: [
    DatePipe,
    { provide: 'IAppParams', useClass: AppParams },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {provide: BASE_PATH, useValue: environment.baseUrl}
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
