import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';

// const routes: Routes = [
//   {
//     path: ':domain/login',
//     loadChildren: () => import('./../login/login.module').then((m) => m.LoginModule),
//   },
//   {
//     path: ':domain',
//     canActivate: [AuthGuard], // Apply AuthGuard
//     loadChildren: () => import('./../login/login.module').then((m) => m.LoginModule),
//   },
//   {
//     path: ':domain/ice',
//     canActivate: [AuthGuard], // Apply AuthGuard
//     loadChildren: () => import('./../layout/layout.module').then((m) => m.LayoutModule),
//   },
//   { path: '', redirectTo: '/ice', pathMatch: 'full' },
//   { path: '**', redirectTo: 'ice/not-found', pathMatch: 'full' }
// ];

const routes: Routes = [
  {
    path: ':domain',
    loadChildren: () => import('./../layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: ':domain/not-found',
    redirectTo: 'soPhia/not-found', pathMatch: 'full'
  },
  {
    path: ':domain/login',
    loadChildren: () => import('./../login/login.module').then((m) => m.LoginModule),
  },
  { path: '', redirectTo: 'soPhia', pathMatch: 'full' },
  { path: '**', redirectTo: 'soPhia/not-found', pathMatch: 'full' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
