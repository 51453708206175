export * from './bot.service';
import { BotService } from './bot.service';
export * from './config.service';
import { ConfigService } from './config.service';
export * from './debug.service';
import { DebugService } from './debug.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './login.service';
import { LoginService } from './login.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './prompts.service';
import { PromptsService } from './prompts.service';
export * from './questions.service';
import { QuestionsService } from './questions.service';
export * from './tags.service';
import { TagsService } from './tags.service';
export * from './threads.service';
import { ThreadsService } from './threads.service';
export const APIS = [BotService, ConfigService, DebugService, DefaultService, DocumentsService, LoginService, NotificationsService, PromptsService, QuestionsService, TagsService, ThreadsService];
