/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type NotificationCategoryEnum = 'email' | 'scrape' | 'index' | 'outdated' | 'audit';

export const NotificationCategoryEnum = {
    Email: 'email' as NotificationCategoryEnum,
    Scrape: 'scrape' as NotificationCategoryEnum,
    Index: 'index' as NotificationCategoryEnum,
    Outdated: 'outdated' as NotificationCategoryEnum,
    Audit: 'audit' as NotificationCategoryEnum
};

