import { InjectionToken } from '@angular/core';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


export let APP_CONFIG = new InjectionToken('app.config');

@Injectable()
export class AppParams {
  public getParams() {
    return environment;
  }
}
