/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type ThreadType = 'public' | 'private' | 'all';

export const ThreadType = {
    Public: 'public' as ThreadType,
    Private: 'private' as ThreadType,
    All: 'all' as ThreadType
};

