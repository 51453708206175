import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn:'root'
})
export class ApiRequestConfiguration {
  apply(req: HttpRequest<any>): HttpRequest<any> {
    const headers: any = {};

   // headers['Content-Type'] = 'application/json';
    return req.clone({
        setHeaders: headers
    });
  }
}
